body {
  margin: 0;
  font-family:  'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --blue: #1A237E;
  --blue-active: #1A237E;
  --orange: #FF6F00;
  --black: #000;

  --white: #FDFDFD;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 99px;
}

::-webkit-scrollbar-thumb {
  background-color: #8C8C8C;
  border-radius: 99px;
  opacity: 0.8;
}

@-moz-document url-prefix() {
  scrollbar {
    width: 8px;
  }

  scrollbar[orient="vertical"] {
    background: #f1f1f1;
  }

  scrollbar[orient="vertical"] thumb {
    background-color: #8C8C8C;
    border-radius: 99px;
    opacity: 0.8;
  }
}
