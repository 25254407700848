p, span, ul, li, h1, h2, h3, h4, ol, button {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

ul, ol, nav {
  list-style: none;
}

body {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

.textBase18 {
  font-size: 18px;
  line-height: 28px;
}

.textBase16 {
  font-size: 16px;
  line-height: 24px;
}

.textBase14 {
  font-size: 14px;
  line-height: 22px;
}

.textBase12 {
  font-size: 12px;
  line-height: 18px;
}

.textBase10 {
  font-size: 10px;
  line-height: 16px;
}

.textSemiBold22 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
}

.textSemiBold18 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}

.textSemiBold16 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.textSemiBold14 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.textSemiBold12 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.textBold28 {
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}

.textBold24 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
}

.container {
  max-width: 1142px;
  width: 100%;
  padding: 0 16px;
  margin: auto;
  box-sizing: border-box;
}
